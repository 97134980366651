import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      // <h1 className="main-heading">
      //   <Link to="/">{title}</Link>
      // </h1>
      <div style={{ backgroundColor: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Link style={{ display: 'flex', alignItems: 'center' }} to="/"> THEERAPRASED </Link>
      </div>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div data-is-root-path={isRootPath}>
      <header className="global-header">
        { header }
      </header>
      <main>{children}</main>
    </div>
  )
}

export default Layout
